.stripe-field-base {
    border-radius: 6px;
    padding: 13px 10px 13px 14px;
    box-sizing: border-box;
    box-shadow: inset 0 0 1px 1px #8C8C8C;
    font-family: Inter;
    font-size: 13px;
    line-height: 21px;
    letter-spacing: -0.1px;
    color: #1A1A1A;
    transition: box-shadow 0.2s;
    height: 44px;
}
.stripe-card-number-container {
    width: 100%;
    max-width: calc(100% - 266px);    
}
#stripe-card-number {
    width: 100%;
}

.exp-wrapper, .cvc-wrapper {
    min-width: 113px;
    margin-left: 20px;
}

#stripe-card-exp, #stripe-card-cvc {
}

.stripe-field-base::placeholder {
    color: #404040;
    font-weight: 400;
}

.stripe-field-base:hover {
    box-sizing: border-box;
    box-shadow: inset 0 0 1px 2px #8C8C8C
}
.stripe-field-focus {
    box-sizing: border-box;
    box-shadow: inset 0 0 1px 2px #404040;
}

.stripe-field-base:disabled {
    box-sizing: border-box;
    box-shadow: inset 0 0 1px 1px #8C8C8C;
    color: #A6A6A6;
}
.stripe-field-base:disabled::placeholder     {
    color: #A6A6A6
}
.stripe-validation-error {
    margin-top: 7px;
}